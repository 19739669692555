// import L, { circle } from "leaflet";

import { defaultConverter, Converter } from "./converter";

// const RAD_TO_DEG = 180 / Math.PI;

// const DEG_TO_RAD = Math.PI / 180;

export { defaultConverter, Converter };

export function computeAngleOne(azimuth, azimuthOffset, angleOfInterest) {
    const baseAngle = (azimuth + azimuthOffset / 2 + 360) % 360;
    return baseAngle - angleOfInterest / 2;
};

export function computeAngleTwo(azimuth, azimuthOffset, angleOfInterest) {
    const baseAngle = (azimuth + azimuthOffset / 2 + 360) % 360;
    return baseAngle + angleOfInterest / 2;
};

export function circlesIntersection(circleTool, circleTool2) {
    console.log(circleTool, circleTool2);

    const [x0, y0] = defaultConverter.convertFromLatLng(...circleTool.center);
    console.log(x0, y0);

    const r0 = +circleTool.radius;

    const [x1, y1] = defaultConverter.convertFromLatLng(...circleTool2.center);
    const r1 = +circleTool2.radius;

    let a,
        dx,
        dy,
        d,
        h,
        rx,
        ry = null;
    let x2,
        y2 = null;

    /* dx and dy are the vertical and horizontal distances between
     * the circle centers.
     */
    dx = x1 - x0;
    dy = y1 - y0;

    /* Determine the straight-line distance between the centers. */
    d = Math.sqrt(dy * dy + dx * dx);

    /* Check for solvability. */
    if (d > r0 + r1) {
        /* no solution. circles do not intersect. */
        return false;
    }

    if (d < Math.abs(r0 - r1)) {
        /* no solution. one circle is contained in the other */
        return false;
    }

    /* 'point 2' is the point where the line through the circle
     * intersection points crosses the line between the circle
     * centers.
     */

    /* Determine the distance from point 0 to point 2. */
    a = (r0 * r0 - r1 * r1 + d * d) / (2.0 * d);

    /* Determine the coordinates of point 2. */
    x2 = x0 + (dx * a) / d;
    y2 = y0 + (dy * a) / d;

    /* Determine the distance from point 2 to either of the
     * intersection points.
     */
    h = Math.sqrt(r0 * r0 - a * a);

    /* Now determine the offsets of the intersection points from
     * point 2.
     */
    rx = -dy * (h / d);
    ry = dx * (h / d);

    /* Determine the absolute intersection points. */
    const xi = x2 + rx;
    const xi_prime = x2 - rx;
    const yi = y2 + ry;
    const yi_prime = y2 - ry;
    const target1 = defaultConverter.convertToLatLng(xi, yi);
    const target2 = defaultConverter.convertToLatLng(xi_prime, yi_prime);
    return { target1, target2 };
}
