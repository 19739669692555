import { Circle } from "react-leaflet";

const CircleTool = ({ circleTool }) => {

    // if (!circleTool || !circleTool.center || !circleTool.radius) {
    //     return null;
    // };

    const { center, radius } = circleTool;

    console.log('center:', center)
    console.log('radius:', radius)
    console.log('circletool')

    return (
        <Circle
            interactive={false}
            center={center}
            // center={center && { lat: center.lat, lng: center.lng }}
            radius={radius}
            weight={2}
            pathOptions={{ color: "red", fillOpacity: 0.2 }}
        ></Circle>
    );
};

export default CircleTool;
