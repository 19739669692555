import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { useRecoilState } from "recoil";
import { shpsSelector } from "../../recoil/selector";
import { clearShpAtom, isShpDisplayId } from "../../recoil/atom";

function AddLayer({ shpId }) {
    const [shpToClear, setShpToClear] = useRecoilState(clearShpAtom);
    const [shpIdToModify, setShpIdToModified] = useRecoilState(isShpDisplayId);
    const shpIdToUse = shpIdToModify ? shpIdToModify : shpId;
    const [shpFile, setShpFile] = useRecoilState(shpsSelector(shpIdToUse));

    const map = useMap();

    function onEachFeature(feature, layer) {
        layer.myTag = shpIdToUse;
        if (feature.properties) {
            let properties = "";
            Object.entries(feature.properties).forEach(([key, value]) => {
                properties = `${properties} <span>${key}: ${value}</span> <br/>`;
            });
            layer.bindPopup(properties);
        }
    }

    const insertShpLayer = (shpFile) => {
        L.geoJSON(shpFile.data, {
            onEachFeature,
        }).addTo(map);
    };

    useEffect(() => {
        // display new shp layer
        if (shpFile && shpFile.isNew === true) {
            insertShpLayer(shpFile);
            const newShp = {
                ...shpFile,
                isNew: false,
            };
            setShpFile(newShp);
        } else if (shpIdToModify) {
            // hide shp layer
            map.eachLayer(function (layer) {
                if (
                    !shpFile.isDisplay &&
                    shpIdToModify === layer.myTag
                ) {
                    map.removeLayer(layer);
                }
            });
            // display hide shp layer
            if (shpFile.isDisplay && shpFile.isModified) {
                insertShpLayer(shpFile);
                const newShp = {
                    ...shpFile,
                    isModified: false,
                };
                setShpFile(newShp);
            }
            setShpIdToModified(null);
        }
    }, [shpFile]);

    useEffect(() => {
        if (shpToClear === "deleteAll") {
            map.eachLayer(function (layer) {
                if (layer.myTag) {
                    map.removeLayer(layer);
                }
            });
            setShpFile(null);
            setShpToClear(null);
        } else if (shpToClear) {
            map.eachLayer(function (layer) {
                if (layer.myTag && layer.myTag === shpToClear) {
                    map.removeLayer(layer);
                }
            });
            setShpFile(null);
            setShpToClear(null);
        }
    }, [shpToClear]);

    return <></>;
}

export default AddLayer;
