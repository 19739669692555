import { useRecoilCallback, useSetRecoilState } from "recoil";
import { LineTool, Polygon, Sensor, ShpFile, Target } from "../models";
import { defaultConverter } from "../utils/converter";
import {
    imageOverlayFamilyAtom,
    imageOverlayIdsAtom,
    isEditMarkerActiveAtom,
    isMovingPositionByClickAtom,
    lineToolFamilyAtom,
    lineToolsIdsAtom,
    polygonFamilyAtom,
    polygonsIdsAtom,
    selectedMarkerIdAtom,
    sensorsFamilyAtom,
    sensorsIdsAtom,
    shpsFamilyAtom,
    shpsIdsAtom,
    targetsFamilyAtom,
    targetsIdsAtom,
} from "../recoil/atom";
import { targetsSelector } from "../recoil/selector";
import { circlesIntersection } from "../utils";
import {
    aoaAlertAtomFamily,
    aoaAlertIdsAtom,
    locationAlertAggregatedAtomFamily,
    locationAlertAggregatedIdsAtom,
    locationAlertAtomFamily,
    locationAlertIdsAtom,
} from "../recoil/atomSockets";
import L from 'leaflet';


export const useMarkerTools = () => {
    const setSelectedMarkerId = useSetRecoilState(selectedMarkerIdAtom);
    const setTarget = useSetRecoilState(targetsSelector());

    const deleteAllMarkers = useRecoilCallback(
        ({ snapshot, reset, set }) =>
            () => {
                const sensorsIds =
                    snapshot.getLoadable(sensorsIdsAtom).contents;
                const targetsIds =
                    snapshot.getLoadable(targetsIdsAtom).contents;
                const lineToolsIds =
                    snapshot.getLoadable(lineToolsIdsAtom).contents;
                const polygonIds =
                    snapshot.getLoadable(polygonsIdsAtom).contents;
                for (let id of sensorsIds[0]) {
                    set(sensorsIdsAtom, (prevValue) => {
                        prevValue[0].delete(id);
                        return [prevValue[0]];
                    });
                    reset(sensorsFamilyAtom(id));
                }

                for (let id of targetsIds[0]) {
                    set(targetsIdsAtom, (prevValue) => {
                        prevValue[0].delete(id);
                        return [prevValue[0]];
                    });
                    reset(targetsFamilyAtom(id));
                }

                for (let id of lineToolsIds[0]) {
                    set(lineToolsIdsAtom, (prevValue) => {
                        prevValue[0].delete(id);
                        return [prevValue[0]];
                    });
                    reset(lineToolFamilyAtom(id));
                }
                for (let id of polygonIds[0]) {
                    set(polygonsIdsAtom, (prevValue) => {
                        prevValue[0].delete(id);
                        return [prevValue[0]];
                    });
                    reset(polygonFamilyAtom(id));
                }
            },
        [],
    );

    const deleteLiveEventsAtom = useRecoilCallback(
        ({ reset, set }) =>
            (marker, type = null) => {
                if (type === "locationAlert") {
                    set(locationAlertIdsAtom, (prevValue) => {
                        prevValue[0].delete(marker);
                        return [prevValue[0]];
                    });
                    reset(locationAlertAtomFamily(marker));
                }
                if (type === "aoaAlert") {
                    set(aoaAlertIdsAtom, (prevValue) => {
                        prevValue[0].delete(marker);
                        return [prevValue[0]];
                    });
                    reset(aoaAlertAtomFamily(marker));
                } else if (type === "locationAlertAggregated") {
                    set(locationAlertAggregatedIdsAtom, (prevValue) => {
                        prevValue[0].delete(marker);
                        return [prevValue[0]];
                    });
                    reset(locationAlertAggregatedAtomFamily(marker));
                }
            },
    );

    const deleteMarkerAtom = useRecoilCallback(
        ({ reset, set }) =>
            (marker, spec_type = null) => {
                // To be reimplemented later

                if (marker.type === Sensor.type) {
                    set(sensorsIdsAtom, (prevValue) => {
                        prevValue[0].delete(marker.id);
                        return [prevValue[0]];
                    });
                    reset(sensorsFamilyAtom(marker.id));
                } else if (marker.type === Target.type) {
                    set(targetsIdsAtom, (prevValue) => {
                        prevValue[0].delete(marker.id);
                        return [prevValue[0]];
                    });
                    reset(targetsFamilyAtom(marker.id));
                } else if (marker.type === LineTool.type) {
                    set(lineToolsIdsAtom, (prevValue) => {
                        prevValue[0].delete(marker.id);
                        return [prevValue[0]];
                    });
                    reset(lineToolFamilyAtom(marker.id));
                } else if (marker.type === ShpFile.type) {
                    set(shpsIdsAtom, (prevValue) => {
                        prevValue[0].delete(marker.id);
                        return [prevValue[0]];
                    });
                    reset(shpsFamilyAtom(marker.id));
                } else if (marker.type === Polygon.type) {
                    set(polygonsIdsAtom, (prevValue) => {
                        prevValue[0].delete(marker.id);
                        return [prevValue[0]];
                    });
                    reset(polygonFamilyAtom(marker.id));
                }
                setSelectedMarkerId(null);
            },
        [],
    );

    const changeVisibility = useRecoilCallback(
        ({ snapshot, set }) =>
            (markerId, type) => {
                let newIsDisplay = false;
                if (type === Sensor.type) {
                    const sensor = snapshot.getLoadable(
                        sensorsFamilyAtom(markerId),
                    ).contents;
                    newIsDisplay = !sensor.isDisplay;
                    const newMarker = {
                        ...sensor,
                        isDisplay: newIsDisplay,
                    };
                    set(sensorsFamilyAtom(markerId), newMarker);
                }

                if (type === Target.type) {
                    const target = snapshot.getLoadable(
                        targetsFamilyAtom(markerId),
                    ).contents;
                    newIsDisplay = !target.isDisplay;

                    const newMarker = {
                        ...target,
                        isDisplay: newIsDisplay,
                    };
                    set(targetsFamilyAtom(markerId), newMarker);
                }
                if (type === LineTool.type) {
                    const line = snapshot.getLoadable(
                        lineToolFamilyAtom(markerId),
                    ).contents;
                    newIsDisplay = !line.isDisplay;
                    const newMarker = {
                        ...line,
                        isDisplay: newIsDisplay,
                    };
                    set(lineToolFamilyAtom(markerId), newMarker);
                }
                if (type === ShpFile.type) {
                    const shp = snapshot.getLoadable(
                        shpsFamilyAtom(markerId),
                    ).contents;
                    newIsDisplay = !shp.isDisplay;
                    const newIsModified = !shp.newIsModified;
                    const newMarker = {
                        ...shp,
                        isDisplay: newIsDisplay,
                        isModified: newIsModified,
                    };
                    set(shpsFamilyAtom(markerId), newMarker);
                }
                if (type === Polygon.type) {
                    const polygon = snapshot.getLoadable(
                        polygonFamilyAtom(markerId),
                    ).contents;
                    newIsDisplay = !polygon.isDisplay;
                    const newIsModified = !polygon.newIsModified;
                    const newMarker = {
                        ...polygon,
                        isDisplay: newIsDisplay,
                        isModified: newIsModified,
                    };
                    set(polygonFamilyAtom(markerId), newMarker);
                }
                return newIsDisplay;
            },
    );

    const getIsEditMarkerActive = useRecoilCallback(
        ({ snapshot }) =>
            async () => {
                return await snapshot.getLoadable(isEditMarkerActiveAtom)
                    .contents;
            },
    );

    const moveElementPosition = useRecoilCallback(
        ({ snapshot, set }) =>
            (e, element) => {
                const isMovingPositionByClick = snapshot.getLoadable(
                    isMovingPositionByClickAtom,
                );
                const selectedMarkerId =
                    snapshot.getLoadable(selectedMarkerIdAtom);

                if (isMovingPositionByClick.contents) {
                    if (
                        selectedMarkerId.contents?.id &&
                        selectedMarkerId.contents.id === element.id
                    ) {
                        const newElement = {
                            ...element,
                            coordinates: [
                                e.latlng.lat,
                                e.latlng.lng,
                                element.coordinates[2],
                            ],
                        };
                        switch (element.type) {
                            case Sensor.type:
                                set(
                                    sensorsFamilyAtom(newElement.id),
                                    newElement,
                                );
                                break;
                            case Target.type:
                                set(
                                    targetsFamilyAtom(newElement.id),
                                    newElement,
                                );
                                break;

                            default:
                                break;
                        }
                    }
                }
            },
    );

    const relationsTargetAndSensors = useRecoilCallback(
        ({ snapshot }) =>
            (targetId) => {
                const sensorsIds =
                    snapshot.getLoadable(sensorsIdsAtom).contents;
                const sensors = Array.from(sensorsIds[0]).map(
                    (id) =>
                        snapshot.getLoadable(sensorsFamilyAtom(id)).contents,
                );
                const target = snapshot.getLoadable(
                    targetsFamilyAtom(targetId),
                ).contents;
                return { target, sensors };
            },
    );

    const createTargetsFromCircleToolIntersection = useRecoilCallback(
        ({ snapshot, set }) =>
            (circleTool) => {
                const { id } = circleTool;
                let hasCreatedTargets = false;
                const sensorsIds =
                    snapshot.getLoadable(sensorsIdsAtom).contents;
                const sensors = Array.from(sensorsIds[0]).map(
                    (id) =>
                        snapshot.getLoadable(sensorsFamilyAtom(id)).contents,
                );

                for (const sensor of sensors) {
                    const { circleTool: circle2 } = sensor;
                    if (!circle2.isDisplay || id === sensor.id) {
                        continue;
                    }

                    const { target1, target2 } = circlesIntersection(
                        circleTool,
                        {
                            ...sensor.circleTool,
                            center: sensor.coordinates.slice(0, 2),
                        },
                    );

                    const newTarget1 = new Target({
                        idAcc: "1",
                        coordinates: [...target1],
                    });
                    const newTarget2 = new Target({
                        idAcc: "2",
                        coordinates: [...target2],
                    });

                    setTarget(newTarget1);
                    setTarget(newTarget2);
                    set(sensorsFamilyAtom(sensor.id), {
                        ...sensor,
                        circleTool: { ...sensor.circleTool, isDisplay: false },
                    });
                    hasCreatedTargets = true;
                }

                return hasCreatedTargets;
            },
    );

    const modelHeaderExport = {
        type: "",
        id: "",
        x: "",
        y: "",
        z: "",
        range: "",
        angleOfInterest: "",
        azimuth: "",
        azimuthOffset: "",
        elevation: "",
        color: "",
        x2: "",
        y2: "",
    };

    const getAllElementsCSV = useRecoilCallback(({ snapshot }) => () => {
        const sensorsIds = snapshot.getLoadable(sensorsIdsAtom).contents;
        const targetsIds = snapshot.getLoadable(targetsIdsAtom).contents;
        const lineToolsIds = snapshot.getLoadable(lineToolsIdsAtom).contents;
        const polygonsIds = snapshot.getLoadable(polygonsIdsAtom).contents;

        const allElements = {
            sensors: [],
            targets: [],
            lineTools: [],
            polygons: [],
        };

        Array.from(sensorsIds[0]).forEach((id) => {
            const sensor = snapshot.getLoadable(sensorsFamilyAtom(id)).contents;
            const [x, y] = defaultConverter.convertFromLatLng(
                ...sensor.coordinates,
            );
            const sensorCSV = {
                ...modelHeaderExport,
                ...sensor,
                x,
                y,
                z: sensor.coordinates[2],
            };
            delete sensorCSV["coordinates"];
            allElements.sensors.push(sensorCSV);
        });

        Array.from(targetsIds[0]).forEach((id) => {
            const target = snapshot.getLoadable(targetsFamilyAtom(id)).contents;
            const [x, y] = defaultConverter.convertFromLatLng(
                ...target.coordinates,
            );
            const targetCSV = {
                ...modelHeaderExport,
                ...target,
                x,
                y,
                z: target.coordinates[2],
            };
            delete targetCSV["coordinates"];
            allElements.targets.push(targetCSV);
        });

        Array.from(lineToolsIds[0]).forEach((id) => {
            const lineTool = snapshot.getLoadable(
                lineToolFamilyAtom(id),
            ).contents;
            const { lat, lng } = lineTool.positions[0];
            const { lat: lat2, lng: lng2 } = lineTool.positions[1];
            const [x, y] = defaultConverter.convertFromLatLng(lat, lng);
            const [x2, y2] = defaultConverter.convertFromLatLng(lat2, lng2);

            const lineToolCSV = {
                ...modelHeaderExport,
                ...lineTool,
                x,
                y,
                x2,
                y2,
            };

            delete lineToolCSV["positions"];
            allElements.lineTools.push(lineToolCSV);
        });
        Array.from(polygonsIds[0]).forEach((id) => {
            const polygon = snapshot.getLoadable(
                polygonFamilyAtom(id),
            ).contents;
            const polygonCSV = {
                ...modelHeaderExport,
                ...polygon,
            };

            for (const [index, value] of Object.entries(polygon.positions)) {
                const [x, y] = defaultConverter.convertFromLatLng(
                    value["lat"],
                    value["lng"],
                );
                polygonCSV[`x${+index + 1}`] = x;
                polygonCSV[`y${+index + 1}`] = y;
                polygonCSV["PolygonPositionsNumber"] = +index + 1;
            }
            delete polygonCSV["positions"];
            allElements.polygons.push(polygonCSV);
        });

        return allElements;
    });

    const headerLocationAlertAggregatorCSV = [
        "alertType",
        "MAC",
        "SSID",
        "eventId",
        "time",
        "X",
        "Y",
        "classification",
        "nbrConnections",
        "status",
        "x_std",
        "y_std",
        "sensor_1_az",
        "sensor_1_el",
        "sensor_1_az_std",
        "sensor_1_el_std",
        "sensor_2_az",
        "sensor_2_el",
        "sensor_2_az_std",
        "sensor_2_el_std",
        "timesAppeared",
    ];
    const getLocationAlertsAggregatedCSV = useRecoilCallback(
        ({ snapshot }) =>
            () => {
                const locationAlertAggregatedIds = snapshot.getLoadable(
                    locationAlertAggregatedIdsAtom,
                ).contents;
                const allLocationAlertAggregated = [
                    headerLocationAlertAggregatorCSV,
                ];
                Array.from(locationAlertAggregatedIds[0]).forEach((id) => {
                    const locationAlertAggregated = snapshot.getLoadable(
                        locationAlertAggregatedAtomFamily(id),
                    ).contents;
                    const tempLocationAlertAggregated = [
                        ...locationAlertAggregated,
                    ];

                    // console.log(locationAlertAggregated);
                    const [x, y] = defaultConverter.convertFromLatLng(
                        +locationAlertAggregated[5],
                        +locationAlertAggregated[6],
                    );
                    tempLocationAlertAggregated[5] = x;
                    tempLocationAlertAggregated[6] = y;

                    allLocationAlertAggregated.push(
                        tempLocationAlertAggregated,
                    );
                });

                return allLocationAlertAggregated;
            },
    );

    const getAllElementsDB = useRecoilCallback(({ snapshot }) => () => {
        const sensorsIds = snapshot.getLoadable(sensorsIdsAtom).contents;
        const targetsIds = snapshot.getLoadable(targetsIdsAtom).contents;
        const lineToolsIds = snapshot.getLoadable(lineToolsIdsAtom).contents;
        const imageOverlayIds =
            snapshot.getLoadable(imageOverlayIdsAtom).contents;
        const polygonsIds = snapshot.getLoadable(polygonsIdsAtom).contents;

        const allElements = {
            sensors: [],
            targets: [],
            lineTools: [],
            static_objects: [],
            images: [],
            polygons: [],
        };

        Array.from(sensorsIds[0]).forEach((id) => {
            const sensor = snapshot.getLoadable(sensorsFamilyAtom(id)).contents;
            const [x, y] = defaultConverter.convertFromLatLng(
                ...sensor.coordinates,
            );
            const sensorDB = {
                ...modelHeaderExport,
                ...sensor,
                x,
                y,
                z: sensor.coordinates[2],
            };
            delete sensorDB["coordinates"];
            allElements.sensors.push(sensorDB);
        });

        Array.from(targetsIds[0]).forEach((id) => {
            const target = snapshot.getLoadable(targetsFamilyAtom(id)).contents;
            const [x, y] = defaultConverter.convertFromLatLng(
                ...target.coordinates,
            );
            const targetDB = {
                ...modelHeaderExport,
                ...target,
                x,
                y,
                z: target.coordinates[2],
            };
            delete targetDB["coordinates"];
            allElements.targets.push(targetDB);
        });

        Array.from(lineToolsIds[0]).forEach((id) => {
            const lineTool = snapshot.getLoadable(
                lineToolFamilyAtom(id),
            ).contents;
            allElements.lineTools.push(lineTool);
        });

        Array.from(imageOverlayIds[0]).forEach((id) => {
            const img = snapshot.getLoadable(
                imageOverlayFamilyAtom(id),
            ).contents;
            allElements.images.push(img);
        });

        Array.from(polygonsIds[0]).forEach((id) => {
            const polygon = snapshot.getLoadable(
                polygonFamilyAtom(id),
            ).contents;
            allElements.polygons.push(polygon);
        });

        return allElements;
    });

    return {
        getAllElementsCSV,
        getAllElementsDB,
        getLocationAlertsAggregatedCSV,
        modelHeaderExport,
        relationsTargetAndSensors,
        moveElementPosition,
        getIsEditMarkerActive,
        deleteMarkerAtom,
        deleteLiveEventsAtom,
        deleteAllMarkers,
        changeVisibility,
        createTargetsFromCircleToolIntersection,
    };
};
