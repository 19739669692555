import { useEffect, useRef } from 'react';

function useEffectAfterMount(callback, array) {

    const flag = useRef(false);
    useEffect(() => {
        flag.current ? callback() : flag.current = true;
    }, array);
}

export default useEffectAfterMount;