import { atom, atomFamily } from "recoil";

export const mapPositionAtom = atom({
    key: "mapPositionAtom",
    default: [31.991764750373207, 34.75536486903987],
});

// atomFamily for sensors
export const sensorsFamilyAtom = atomFamily({
    key: "sensorsFamilyAtom",
    default: null,
});

export const sensorsIdsAtom = atom({
    key: "sensorsIdsAtom",
    default: [new Set([])],
});

export const allSensorsAtom = atom({
    key: 'allSensors',
    default: [],
});

// atomFamily for shape files

export const shpsFamilyAtom = atomFamily({
    key: "shpsFamilyAtom",
    default: null,
});

export const shpsIdsAtom = atom({
    key: "shpsIdsAtom",
    default: [new Set([])],
});

export const clearShpAtom = atom({
    key: "clearShpAtom",
    default: null,
});

export const isShpDisplayId = atom({
    key: "isShpDisplayId",
    default: null,
});

// for display sensors on map
export const typeOfNewMarkerAtom = atom({
    key: "typeOfNewMarkerAtom",
    default: null,
});

export const selectedMarkerIdAtom = atom({
    key: "selectedMarkerIdAtom",
    default: null,
});

export const flyToElementIdAtom = atom({
    key: "flyToElementIdAtom",
    default: null,
});

export const isMovingPositionByClickAtom = atom({
    key: "isMovingPositionByClickAtom",
    default: false,
});

// atomFamily for imagesOverlay
export const imageOverlayFamilyAtom = atomFamily({
    key: "imageOverlayFamilyAtom",
    default: null,
});

export const imageOverlayIdsAtom = atom({
    key: "imageOverlayIdsAtom",
    default: [new Set([])],
});




export const isEditMarkerActiveAtom = atom({
    key: "isEditMarkerActiveAtom",
    default: true,
});



// atomFamily for linetools
export const lineToolFamilyAtom = atomFamily({
    key: "lineToolFamilyAtom",
    default: null,
});

export const lineToolsIdsAtom = atom({
    key: "lineToolsIdsAtom",
    default: [new Set([])],
});


// atomFamily for polygons
export const polygonFamilyAtom = atomFamily({
    key: "polygonFamilyAtom",
    default: null,
});

export const polygonsIdsAtom = atom({
    key: "polygonsIdsAtom",
    default: [new Set([])],
});


// atomFamily for targets
export const targetsFamilyAtom = atomFamily({
    key: "targetsFamilyAtom",
    default: null,
});

export const targetsIdsAtom = atom({
    key: "targetsIdsAtom",
    default: [new Set([])],
});




//form to create the new targets from a lineTool
export const targetsFromLineFormAtom = atom({
    key: "targetsFromLineFormAtom",
    default: [],
});

// set topic
export const subscribeTopicAtom = atom({
    key: 'subscribeTopicAtom',
    default: '', // Initial value of the subscribed topic
});

//set event source
export const eventSourceAtom = atom({
    key: 'eventSourceAtom',
    default: {}, // Initial value of the event source
});

//received live messages
export const receivedMessagesAtom = atom({
    key: 'receivedMessagesAtom',
    default: [], // Initial value of the received message
});

export const showSensorConfigAtom = atom({
    key: 'showSensorConfigAtom',
    default: true,
});