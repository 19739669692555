import React, { useState } from 'react';
import MapContext from './MapContext';

const MapProvider = ({ children }) => {
  const [map, setMap] = useState(null);
  // console.log('running mapprovider')

  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;
