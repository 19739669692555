import SensorMarker from "./SensorMarker";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { typeOfNewMarkerAtom, sensorsIdsAtom } from "../../../recoil/atom";
import { useMapEvents } from "react-leaflet";
// import { useMarkerTools } from "../../../hooks/useMarkerTools";
import { sensorsSelector } from "../../../recoil/selector";
import { Sensor } from "../../../models";

const ListSensorMarker = () => {
    const sensorsIds = useRecoilValue(sensorsIdsAtom);
    const [typeOfNewMarker, setTypeOfNewMarker] =
        useRecoilState(typeOfNewMarkerAtom);

    const setSensor = useSetRecoilState(sensorsSelector());

    useMapEvents({
        click: (e) => {
            if (typeOfNewMarker === "sensor") {
                const newSensor = new Sensor({
                    coordinates: [e.latlng.lat, e.latlng.lng, 0],
                });
                setSensor(newSensor);
                setTypeOfNewMarker(null);
            }
        },
    });
    return (
        <div>
            {Array.from(sensorsIds[0]).map((sensorId, index) => (
                <div key={index}>{<SensorMarker sensorId={sensorId} />}</div>
            ))}
        </div>
    );
};

export default ListSensorMarker;
