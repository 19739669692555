import axios from "axios";

const inDev = false;
const inProd = false;
const inBackEnd = false;

const baseURL = inDev
    ? "http://127.0.0.1:5010"
    : inProd
        ? "https://alert-system.guardf.app"
        : "https://dev-alert-system.guardf.app";


const api = axios.create({
    baseURL: baseURL,
    withCredentials: false,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
        email: localStorage.getItem("email"),
        "Authorization": localStorage.getItem("token"),
    },
});

export const registerUser = (firstName, lastName, email, password) => api.post("/registerUser", { firstName, lastName, email, password });

export const loginUser = (email, password) => api.post("/loginUser", { email, password });
export const loginUserGoogle = (email) => api.post("/loginUser/google", { email });