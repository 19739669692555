import React, { useEffect, useState } from "react";
import {
    InputLabel,
    MenuItem,
    Select,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Switch,
    Box,
    Button,
    DialogContentText,
    TextField,
    Typography
} from "@mui/material";

import { clientsData, editUserDetails } from "../../api/admin";
import { getReverseIndexFromDB } from "../../api/connection";

const UserEditModal = ({ open, close, data, currentUserClient, currentUserEmail, getAllUsers }) => {
    const [reverseIndex, setReverseIndex] = useState()
    const [clientList, setClientList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    // const [isAdmin, setIsAdmin] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [role, setRole] = useState();
    const [client, setClient] = useState();
    const [project, setProject] = useState();
    const [site, setSite] = useState();

    useEffect(() => {
        fetchClientData();
        setFirstName(data.first_name);
        setLastName(data.family_name);
        setEmail(data.email);

        if (data.client === 'N/A') {
            setClient('');
        } else {
            setClient(data.client);
        }

        if (project === null) {
            setProject('');
        } else {
            setProject(data.project);
        }
        if (site === null) {
            setSite('');
        } else {
            setSite(data.site);
        }
        // setIsAdmin(data.role === 'admin');
        setRole(data.role)

    }, [data]);

    useEffect(() => {
        console.log(client)
        console.log('running fecth proj')
        fetchProjectData();
    }, [client]);

    useEffect(() => {
        if (client && project && reverseIndex) {
            console.log(project)
            console.log('running fecth site')
            fetchSiteData();
        }
    }, [project, reverseIndex]);

    const fetchClientData = async () => {
        const allClientsData = await clientsData();
        const clientsList = allClientsData.data.map(item => item.name);
        const newClientOptions = clientsList.filter(item => item !== 'N/A');
        const orderedClientOptions = [...newClientOptions].sort((a, b) => a.localeCompare(b));
        setClientList(orderedClientOptions);
    };

    const fetchProjectData = async () => {
        try {
            if (client) {
                const response = await getReverseIndexFromDB(client);
                setReverseIndex(response.data[client])
                console.log('response:', response.data[client]);
                const responseProjectList = (Object.keys(response.data[client]));
                const orderedProjectList = [...responseProjectList].sort((a, b) => a.localeCompare(b));
                setProjectList(orderedProjectList);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const fetchSiteData = async () => {
        try {
            console.log(reverseIndex)
            const responseSiteList = (Object.keys(reverseIndex[project]));
            console.log('responseSiteList:', responseSiteList)
            const orderedSiteList = [...responseSiteList].sort((a, b) => a.localeCompare(b));
            setSiteList(orderedSiteList)
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmit = async () => {
        // const userRole = isAdmin ? 'admin' : 'user';
        const userRole = role;
        const userId = data.user_id;
        try {
            await editUserDetails(firstName, lastName, email, userRole, client, project, site, userId);
            getAllUsers();
        } catch (error) {
            console.error('Error deleting project:', error);
        } finally {
            close();
        }
    };

    // const adminChange = async (e) => {
    //     setIsAdmin(!isAdmin);
    // };

    const handleRoleVals = (event) => {
        setRole(event.target.value);
    };

    const handleClientVals = (event) => {
        setClient(event.target.value);
        setProject('');
        setSite('');
    };

    const handleProjectVals = (event) => {
        setProject(event.target.value);
        setSite('');
    };

    const handleSiteVals = (event) => {
        setSite((event.target.value));
    };

    // console.log(project);
    // console.log(site);
    // console.log(siteList);
    console.log(role);

    return (
        <Dialog
            onClose={close}
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ '& .MuiDialog-paper': { width: '40%', maxWidth: 'none' } }}
        >
            <DialogTitle id="alert-dialog-title">{`Edit User: ${data.email}`}</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <DialogContentText id="alert-dialog-description" sx={{ marginLeft: "2vw" }}>
                        First name:
                    </DialogContentText>
                    <TextField
                        id="standard-multiline-flexible"
                        value={firstName}
                        margin="normal"
                        sx={{ width: '60%', marginRight: "2vw" }}
                        onChange={(e) => { setFirstName(e.target.value) }}
                    />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <DialogContentText id="alert-dialog-description" sx={{ marginLeft: "2vw" }}>
                        Last name:
                    </DialogContentText>
                    <TextField
                        id="standard-multiline-flexible"
                        value={lastName}
                        margin="normal"
                        sx={{ width: '60%', marginRight: "2vw" }}
                        onChange={(e) => { setLastName(e.target.value) }}
                    />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "2vh" }}>
                    <DialogContentText id="alert-dialog-description" sx={{ marginLeft: "2vw" }}>
                        Email:
                    </DialogContentText>
                    <TextField
                        id="standard-multiline-flexible"
                        value={email}
                        margin="normal"
                        sx={{ width: '60%', marginRight: "2vw" }}
                        onChange={(e) => { setEmail(e.target.value) }}
                    />
                </Box>
                {currentUserClient === "ADK" ? (
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "3vh" }}>
                        <InputLabel
                            id="demo-simple-select-filled-label"
                            sx={{ marginLeft: "2vw" }}
                        >
                            Client:
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={client || ''}
                            sx={{ width: '60%', marginRight: "2vw" }}
                            onChange={handleClientVals}
                        >
                            {clientList.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                ) : (
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "3vh" }}>
                        <InputLabel
                            id="demo-simple-select-filled-label"
                            sx={{ marginLeft: "2vw" }}
                        >
                            Client:
                        </InputLabel>
                        <Typography sx={{ width: '60%', marginRight: "2vw" }}>{client}</Typography>
                    </Box>
                )}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "3vh" }}>
                    <InputLabel
                        id="demo-simple-select-filled-label"
                        sx={{ marginLeft: "2vw" }}
                    >
                        Project:
                    </InputLabel>
                    <Select
                        disabled={client === ''}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={project || ''}
                        sx={{ width: '60%', marginRight: "2vw" }}
                        onChange={handleProjectVals}
                    >
                        {projectList.map((item, index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "3vh" }}>
                    <InputLabel
                        id="demo-simple-select-filled-label"
                        sx={{ marginLeft: "2vw" }}
                    >
                        Site:
                    </InputLabel>
                    <Select
                        disabled={!project}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={site || ''}
                        sx={{ width: '60%', marginRight: "2vw" }}
                        onChange={handleSiteVals}
                    >
                        {siteList.map((item, index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                {currentUserEmail !== data.email &&
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <InputLabel
                            id="demo-simple-select-filled-label"
                            sx={{ marginLeft: "2vw" }}
                        >
                            Role:
                        </InputLabel>
                        <Select
                            disabled={role === ''}
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={role || ''}
                            sx={{ width: '60%', marginRight: "2vw" }}
                            onChange={handleRoleVals}
                        >
                            <MenuItem value='user'>User</MenuItem>
                            {currentUserClient === "ADK" && data.client !== "ADK" && client !== "ADK" ? (
                                <MenuItem value='admin'>Sub-Admin</MenuItem>
                            ) : (
                                <MenuItem value='admin'>Admin</MenuItem>
                            )}
                        </Select>
                    </Box>
                }
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                    disabled={role === 'admin' ? !client : !site}
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserEditModal;