import React from 'react';
import { Routes, Route } from "react-router-dom";
import Nav from "./components/nav/Nav";
import AlertsPage from "./components/AlertsPage";
import RegisterPage from "./components/RegisterPage";
import ApprovalPage from "./components/ApprovalPage";
import LoginPage from "./components/LoginPage";
import ConnectionPage from "./components/ConnectionPage";
import AdminUsersPage from "./components/admin/AdminUsersPage";
import InformationPage from "./components/InformationPage";

function App() {
  return (
    <div>
      <Nav />
      <main className="main">
        <Routes>
          <Route path="/" element={<AlertsPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/register/approval" element={<ApprovalPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/connection" element={<ConnectionPage />} />
          <Route path="/admin/users" element={<AdminUsersPage />} />
          <Route path="/info" element={<InformationPage />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;