import React, { useEffect, useState } from "react";
import { getAllUsersData, approveUser } from "../../api/admin";
import UserDeleteModal from "./UserDeleteModal";
import UserEditModal from "./UserEditModal";

import { Box, CircularProgress, Button, TableHead, Stack, styled, Switch, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const AdminUsersPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentUserDict, setCurrentUserDict] = useState({});
    const [data, setData] = useState({});
    const [editPopup, setEditPopup] = useState(false);
    const [deleteUserEmail, setDeleteUserEmail] = useState(null)
    const [deletePopup, setDeletePopup] = useState(false);
    const [generatedClientDict, setGeneratedClientDict] = useState({});
    const headers = [
        "Client",
        "First name",
        "Last name",
        "Email",
        "Role",
        "Project",
        "Site",
        "Approved",
        ""
    ];

    useEffect(() => {
        getAllUsers();
    }, []);

    useEffect(() => {
        arrangeList();
    }, [users]);

    const getAllUsers = async () => {
        const user_data = JSON.parse(localStorage.getItem('user'))
        const client = user_data['client'];
        console.log(client)
        try {
            const allUserData = await getAllUsersData(client);
            console.log(allUserData)
            setUsers(allUserData.data);
            const currentUser = localStorage.getItem("user")
            const { token, ...extractedObject } = JSON.parse(currentUser);
            setCurrentUserDict(extractedObject)
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    const arrangeList = () => {
        const clientList = [];
        const clientDict = {};

        for (let item of users) {
            clientList.push(item);
        }
        clientList.sort((a, b) => a.client.localeCompare(b.client));

        for (let item of clientList) {
            const client = item.client;
            if (client in clientDict) {
                clientDict[client].push(item);
            } else {
                clientDict[client] = [item];
            }
        }
        for (let client in clientDict) {
            clientDict[client].sort((a, b) => a.first_name.localeCompare(b.first_name));
        }
        setGeneratedClientDict(clientDict);
    };

    // FOR EDIT POPUP
    const handleClickOpenEdit = (data = {}) => {
        setData(data);
        setEditPopup(true);
    };

    const handleCloseEdit = () => {
        setData({});
        setEditPopup(false);
    };

    // FOR DELETE POPUP
    const handleClickOpenDelete = (row) => {
        setDeleteUserEmail(row.email)
        setDeletePopup(true);
    };

    const handleCloseDelete = () => {
        setDeletePopup(false);
    };

    // CHANGE APPROVAL OF USER
    const approve_user = async (email, command) => {
        await approveUser(email, command)
        getAllUsers()
    }

    // TOGGLE STYLE
    const AntSwitch = styled(({ rowEmail, ...otherProps }) => <Switch {...otherProps} />)(({ theme, rowEmail }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: currentUserDict.email === rowEmail ? 'grey' : '#4caf50',
                },
            },
            '&:not(.Mui-checked) + .MuiSwitch-track': {
                backgroundColor: '#f44336', // Red color when the switch is unchecked
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            boxSizing: 'border-box',
        },
    }));

    console.log('generatedclientdict:', generatedClientDict)
    console.log('currentUserDict:', currentUserDict)
    console.log(localStorage.getItem("page"))

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "5vh",
                    marginBottom: "3vh"
                }}
            >
                <Typography
                    sx={{
                        fontSize: "40px",
                        fontWeight: "bold"
                    }}
                >
                    Users
                </Typography>
            </Box>

            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {users && Object.keys(generatedClientDict) &&
                        Object.values(generatedClientDict).map((clients, indexOfClient) => (
                            <TableContainer key={indexOfClient} component={Paper} style={{ marginBottom: "20px" }}>
                                <Table
                                    sx={{ minWidth: 500 }}
                                    aria-label="custom pagination table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            {headers.map(
                                                (header, index) =>
                                                    <TableCell
                                                        key={index}
                                                        style={{ width: 160, fontWeight: "bold" }}
                                                        align="center"
                                                    >
                                                        {indexOfClient === 0 ? header : ''}
                                                    </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody key={indexOfClient}>
                                        {clients &&
                                            clients.map((row, index) => (

                                                <TableRow key={index}>
                                                    <TableCell
                                                        style={{ width: 160 }}
                                                        align="center"
                                                    >
                                                        {row.client === 'N/A' ? '' : row.client}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ width: 160 }}
                                                        align="center"
                                                    >
                                                        {row.first_name}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ width: 160 }}
                                                        align="center"
                                                    >
                                                        {row.family_name}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ width: 200 }}
                                                        align="center"
                                                    >
                                                        {row.email}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ width: 200 }}
                                                        align="center"
                                                    >
                                                        {
                                                            currentUserDict.client && currentUserDict.client.toLowerCase() === 'adk' && row.role && row.client.toLowerCase() === 'adk' && row.role.toLowerCase() === 'admin' ? (
                                                                <>Admin</>
                                                            ) : (
                                                                currentUserDict.client && currentUserDict.client.toLowerCase() !== 'adk' && row.role && row.role.toLowerCase() === 'admin' ? (
                                                                    <>Admin</> // For loggedin admin whos client is not ADK
                                                                ) : (
                                                                    currentUserDict.client && currentUserDict.client.toLowerCase() === 'adk' && row.client && row.client.toLowerCase() !== 'adk' && row.role.toLowerCase() === 'admin' ? (
                                                                        <>Sub-Admin</>
                                                                    ) : (
                                                                        <>
                                                                            {row.User_Groups &&
                                                                                row.User_Groups.length > 0 &&
                                                                                row.User_Groups[0].user_role !== null ? (
                                                                                <>
                                                                                    {row.User_Groups[0].user_role}
                                                                                </>
                                                                            ) : (
                                                                                <>User</>
                                                                            )}
                                                                        </>
                                                                    )
                                                                )
                                                            )
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ width: 160 }}
                                                        align="center"
                                                    >
                                                        {row.project}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ width: 160 }}
                                                        align="center"
                                                    >
                                                        {row.site}
                                                    </TableCell>
                                                    <TableCell style={{ width: 100 }}
                                                        align="center"
                                                    >
                                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                                            <AntSwitch
                                                                disabled={currentUserDict.email === row.email}
                                                                defaultChecked={row.approved === 1}
                                                                inputProps={{ 'aria-label': 'ant design' }}
                                                                rowEmail={row.email}
                                                                onChange={() => {
                                                                    const newApprovedStatus = row.approved === 1 ? 0 : 1;
                                                                    approve_user(row.email, newApprovedStatus);
                                                                }}
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ width: 160, display: "flex", justifyContent: "center" }}
                                                        align="center"
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            color="success"
                                                            onClick={() => handleClickOpenEdit(row)}
                                                            size="small"
                                                            style={{ textTransform: 'none', marginRight: '3px' }}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <UserEditModal
                                                            open={editPopup}
                                                            close={handleCloseEdit}
                                                            data={data}
                                                            currentUserClient={currentUserDict.client}
                                                            currentUserEmail={currentUserDict.email}
                                                            getAllUsers={getAllUsers}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() => handleClickOpenDelete(row)}
                                                            size="small"
                                                            style={{ textTransform: 'none' }}
                                                        >
                                                            Delete
                                                        </Button>

                                                        <UserDeleteModal
                                                            open={deletePopup}
                                                            close={handleCloseDelete}
                                                            data={deleteUserEmail}
                                                            getAllUsers={getAllUsers}
                                                        />

                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        ))}
                </>
            )}
        </Box>
    );
};

export default AdminUsersPage