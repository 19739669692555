import { atom, atomFamily } from "recoil";

export const aoaAlertAtomFamily = atomFamily({
    key: "aoaAlertAtomFamily",
    default: [],
});

export const aoaAlertIdsAtom = atom({
    key: "aoaAlertIdsAtom",
    default: [new Set([])],
});

export const locationAlertAtomFamily = atomFamily({
    key: "locationAlertAtomFamily",
    default: [],
});

export const locationAlertIdsAtom = atom({
    key: "locationAlertIdsAtom",
    default: [new Set([])],
});

export const locationAlertAggregatedAtomFamily = atomFamily({
    key: "locationAlertAggregatedAtomFamily",
    default: [],
});

export const locationAlertAggregatedIdsAtom = atom({
    key: "locationAlertAggregatedIdsAtom",
    default: [new Set([])],
});