import axios from "axios";

const inDev = false;
const inProd = false;
const inBackEnd = false;

const baseURL = inDev
    ? "http://127.0.0.1:5010"
    : inProd
        ? "https://alert-system.guardf.app"
        : "https://dev-alert-system.guardf.app";


const api = axios.create({
    baseURL: baseURL,
    withCredentials: false,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
        email: localStorage.getItem("email"),
        "Authorization": localStorage.getItem("token"),
    },
});

export const getAllUsersData = (client) => api.get("/get_all_users_data", { params: { client: client } });
export const approveUser = (email, command) => api.post("/user_approval", { email, command });
export const clientsData = () => api.get('/clientsData');
export const editUserDetails = (firstName, lastName, email, role, client, project, site, userId) => api.post('/edit_user_details', { firstName, lastName, email, role, client, project, site, userId })
export const deleteUser = (email) => api.post("/delete_user", { email });