import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { deleteUser } from "../../api/admin";

const UserDeleteModal = ({ open, close, data, getAllUsers }) => {

    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            console.log(data)
            await deleteUser(data);
            getAllUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
        } finally {
            close();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete user <strong>{data}</strong>?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <Button onClick={handleDelete}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserDeleteModal;