import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { shpsIdsAtom } from "../../recoil/atom";
import AddLayer from "./AddLayer";

export default function ShpJSON() {
  const shpIds = useRecoilValue(shpsIdsAtom)
  const [shpId, setShpId] = useState('');

  useEffect(() => {
    const [test] = shpIds
    const iterator = test.entries();
    for (const entry of iterator) {
      setShpId(entry[0])
    };
  }, [shpIds]);

  // console.log('running shp')

  return (
    <>
      {shpId && <AddLayer shpId={shpId} />}
    </>
  );
}
