import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, FormLabel, Typography } from '@mui/material';
import { registerUser } from "../api/auth.js";
import { isValidEmail } from '../functions/authFunctions.js';


const RegisterPage = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const navigate = useNavigate();


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            navigate('/');
        }
    }, []);


    const register = async () => {
        // console.log(email, password);
        if (password !== confirmedPassword) {
            setErrorMsg("Passwords don't match");
            return;
        } else {
            if (firstName !== "" && lastName !== "" && isValidEmail(email) && password !== "") {
                try {
                    const response = await registerUser(firstName, lastName, email, password);
                    localStorage.setItem("user", JSON.stringify(response.data));
                    navigate("/register/approval");
                } catch (error) {
                    setErrorMsg(error.response.data.error)
                    console.log(error)
                }
            } else if (firstName !== "" && lastName !== "" && !isValidEmail(email) && password !== "") {
                setErrorMsg("Please enter a valid email")
            } else {
                setErrorMsg("All fields must be filled")
            }
        };
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: '100vh'
            }}
        >
            <FormLabel
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "4vh",
                    border: "1px solid grey",
                    borderRadius: "8px"
                }}
            >
                <TextField
                    required
                    sx={{
                        margin: "5vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="firstName"
                    label="First Name"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => { setFirstName(e.target.value) }}
                />
                <TextField
                    required
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="lastName"
                    label="Last Name"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => { setLastName(e.target.value) }}
                />
                <TextField
                    required
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                />
                <TextField
                    required
                    type="password"
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="password"
                    label="Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                />
                <TextField
                    required
                    type="password"
                    sx={{
                        margin: "0vh 5vh 5vh 5vh",
                        width: "300px",
                    }}
                    id="confirmPassword"
                    label="Confirm password"
                    variant="outlined"
                    value={confirmedPassword}
                    onChange={(e) => { setConfirmedPassword(e.target.value) }}
                />
            </FormLabel>
            <Typography
                sx={{ color: "red" }}
            >
                {errorMsg}
            </Typography>
            <Button
                variant="contained"
                sx={{
                    width: "370px",
                    backgroundColor: "#0A1B3A",
                    borderRadius: "30px",
                    '&:hover': {
                        backgroundColor: "#37465E",
                    },
                }}
                onClick={() => { register() }}
            >
                Sign up
            </Button>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "2vh"
                }}
            >
                <Typography>Already have an account?</Typography>
                <Button
                    sx={{ fontWeight: "bold" }}
                    component={Link}
                    to={`/Login`}
                >
                    Sign in
                </Button>
            </Box>
        </Box>
    )
}

export default RegisterPage;