import { useEffect } from "react";
import L from "leaflet";
import { useRecoilValue } from "recoil";
import { receivedMessagesAtom } from "../../../recoil/atom";
import locationImage from '../../../images/location.png';
import { defaultConverter } from "../../../utils";


const LiveDataMarker = ({ map }) => {
    const receivedMessages = useRecoilValue(receivedMessagesAtom);

    useEffect(() => {
        const customIcon = L.icon({
            iconUrl: locationImage,
            iconSize: [32, 32], // size of the icon
            iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -32] // point from which the popup should open relative to the iconAnchor
        });

        map.eachLayer(layer => {
            if (layer instanceof L.Marker) {
                map.removeLayer(layer);
            }
        });

        receivedMessages.forEach(message => {
            const X = message.payload.XY[0];
            const Y = message.payload.XY[1];

            if (X && Y) {
                const position = defaultConverter.convertToLatLng(X, Y);
                // L.marker(position, { icon: customIcon }).addTo(map).bindPopup(`${message.payload.main}, ${message.payload.sub}`);

                // Create marker
                const marker = L.marker(position, { icon: customIcon }).addTo(map);

                // Create and bind popup
                const popupContent = `${message.payload.main}, ${message.payload.sub}`;

                const popupOptions = {
                    closeButton: false // Disable close button
                };

                marker.bindPopup(popupContent, popupOptions);

                // Show popup on mouseover
                marker.on('mouseover', function (e) {
                    this.openPopup();
                });

                // Hide popup on mouseout
                marker.on('mouseout', function (e) {
                    this.closePopup();
                });
            }
        });

    }, [receivedMessages, map]);


    return null; // Marker is added to the map directly, no need to render anything
};

export default LiveDataMarker;