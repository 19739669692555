import { getTopicPosition } from "../api/connection";
import { defaultConverter } from "../utils/converter";
import { Sensor } from "../models";

export const getSelectedDataPosition = async (data, setMapPosition) => {
    try {
        const topicPosition = await getTopicPosition(data);
        // console.log('topicPosition:', topicPosition)

        const { x, y, cluster_sensors } = topicPosition.data;
        const [lat, lng] = defaultConverter.convertToLatLng(+x, +y);
        setMapPosition([lat, lng]);
        return cluster_sensors
    } catch (err) {
        console.log(err);
    }
};

export const createSensors = (cluster_sensors, setSensor) => {
    // console.log('cluster_sensors:', cluster_sensors)
    if (cluster_sensors.length) {
        for (const sensor of cluster_sensors) {
            const {
                id,
                location,
                orientation,
                range,
                foi,
                azimuth,
                elevation,
                height,
            } = sensor;
            // console.log(orientation, azimuth)
            const [lat, lng] = defaultConverter.convertToLatLng(
                +location[0],
                +location[1],
            );
            const newSensor = new Sensor({
                id: id,
                coordinates: [lat, lng, +height],
                range: range,
                angleOfInterest: foi,
                azimuth: orientation,
                elevation: elevation,
                azimuthOffset: azimuth,
            });
            setSensor(newSensor);
            // console.log(newSensor)
        }
    } else console.log("error creating sensors");
};



export const handleOnMessage = (eventSource, setReceivedMessages) => {
    if (eventSource) {
        eventSource.onmessage = function (event) {
            const message = JSON.parse(event.data);
            const payloadValue = JSON.parse(message.payload);
            const newMessage = { 'topic': message.topic, 'payload': payloadValue }

            setReceivedMessages(prevMessages => {
                const filteredMessages = prevMessages.filter(message => message.payload.object_id !== newMessage.payload.object_id);
                return [newMessage, ...filteredMessages];
            });
        };
    } else {
        console.error("EventSource is not ready");
    }
};

// MAYBE WAY TO HANDLE IT IN THE FUTURE
// export const handleIncrementCount = (eventSource, mqttTopic) => {
//     if (eventSource) {
//         eventSource.onopen = () => {
//             console.log("Connected to SSE");
//             // Inform backend about the new connection
//             fetch(`/increment_connection_count?topic=${encodeURIComponent(mqttTopic)}`, { method: 'POST' });
//         };
//     } else {
//         console.error("EventSource is not ready for open event");
//     }
// };

// export const handleDecrementCount = (eventSource, mqttTopic) => {
//     if (eventSource) {
//         console.lof(eventSource)
//         eventSource.onclose = () => {
//             console.log("Disconnected from SSE");
//             // Inform backend about the disconnection
//             fetch(`/decrement_connection_count?topic=${encodeURIComponent(mqttTopic)}`, { method: 'POST' });
//         };
//     } else {
//         console.error("EventSource is not ready for close event");
//     };
// };
