import axios from "axios";

const inDev = false;
const inProd = false;
const inBackEnd = false;

const baseURL = inDev
    ? "http://127.0.0.1:5010"
    : inProd
        ? "https://alert-system.guardf.app"
        : "https://dev-alert-system.guardf.app";


const api = axios.create({
    baseURL: baseURL,
    withCredentials: false,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Content-Type": "application/json",
        email: localStorage.getItem("email"),
        "Authorization": localStorage.getItem("token"),
    },
});

export const getClientPermissions = (clientId) => api.get("/get_client_permissions", { params: { clientId } });
export const getReverseIndexFromDB = (permissionsListString) => api.get("/get_reverse_index", { params: { permissionsListString } });
export const getTopicPosition = (topic) => api.get("/topic/position", { params: { topic: topic } });