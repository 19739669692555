import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';


const ApprovalPage = () => {

    const [userData, setUserData] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        const storedUserData = localStorage.getItem('user');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
        }
    }, []);

    // console.log(userData.first_name)
    return (
        <Box>
            {userData && userData.first_name && (
                <Typography>
                    Thank you {userData.first_name}!
                    <br />
                    Your user request has been sent and pending approval.
                </Typography>
            )}
        </Box>
    )
}

export default ApprovalPage;