import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, FormLabel } from '@mui/material';
import { loginUser, loginUserGoogle } from "../api/auth";
import { isValidEmail, isTokenExpired } from '../functions/authFunctions';

import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

// FOR CONNECTION BUTTOM
import { getSelectedDataPosition, createSensors } from "../functions/connectionFunctions"
import { useSetRecoilState, useRecoilState } from "recoil";
import { mapPositionAtom, subscribeTopicAtom, receivedMessagesAtom } from "../recoil/atom";
import { sensorsSelector } from "../recoil/selector";


const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [googleErrorMsg, setGoogleErrorMsg] = useState("");

    const user = localStorage.getItem("user");
    const client = user ? JSON.parse(user).client : null;
    const project = user ? JSON.parse(user).project : null;
    const site = user ? JSON.parse(user).site : null;
    const topicPosition = `data/${client}/${project}/${site}/+`;
    const mqttTopic = `${topicPosition}/7`;

    const setMapPosition = useSetRecoilState(mapPositionAtom);
    const setSensor = useSetRecoilState(sensorsSelector());

    const [subscribeTopic, setSubscribeTopic] = useRecoilState(subscribeTopicAtom);
    const [receivedMessages, setReceivedMessages] = useRecoilState(receivedMessagesAtom);

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
        if (!isTokenExpired(token)) {
            if (user.data.role === "admin") {
                navigate("/connection");
            } else {
                // subscribed topic is used for when you decrement in the future. left in
                setSubscribeTopic(mqttTopic);
                navigate("/");
            };
        };
    }, []);

    const logInUser = async () => {
        if (isValidEmail(email) && password !== "") {
            try {
                const response = await loginUser(email, password);
                console.log(response.data.role)
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("token", response.data.token);
                if (response.data.role === "user") {
                    const cluster_sensors = await getSelectedDataPosition(topicPosition, setMapPosition);
                    console.log(cluster_sensors)
                    createSensors(cluster_sensors, setSensor);

                    // Connect to SSE endpoint
                    const eventSource = new EventSource(`/get_live_data?topic=${encodeURIComponent(mqttTopic)}`);

                    eventSource.onmessage = function (event) {
                        const message = JSON.parse(event.data);
                        const payloadValue = JSON.parse(message.payload);
                        const newMessage = { 'topic': message.topic, 'payload': payloadValue }
            
                        setReceivedMessages(prevMessages => {
                            const filteredMessages = prevMessages.filter(message => message.payload.object_id !== newMessage.payload.object_id);
                            return [newMessage, ...filteredMessages];
                        });
                    };
                     // MAYBE WAY TO HANDLE IT IN THE FUCTURE
                    // handleOnMessage(eventSource);
                    // handleIncrementCount(eventSource, mqttTopic);

                    localStorage.setItem("showSettings", "yes");
                    localStorage.setItem("page", "alertsPage");
                    setReceivedMessages([]); // to clear and data that came in before
                    setSubscribeTopic(mqttTopic);
                    navigate('/')
                } else {
                    localStorage.setItem("showSettings", "no");
                    localStorage.setItem("page", "connectionPage");
                    navigate('/connection');
                };
            } catch (error) {
                console.log(error)
                setErrorMsg(error.response.data.error)
            }
        } else if (!isValidEmail(email) && email !== "" && password !== "") {
            setErrorMsg("Please enter a valid email")
        } else {
            setErrorMsg("All fields must be filled")
        }
    };

    const googleLogInUser = async (email) => {
        try {
            const response = await loginUserGoogle(email);
            console.log(response)
            localStorage.setItem("user", JSON.stringify(response.data));
            localStorage.setItem("token", response.data.token);
            if (response.data.role === "user") {
                const cluster_sensors = await getSelectedDataPosition(topicPosition, setMapPosition);
                console.log(cluster_sensors)
                createSensors(cluster_sensors, setSensor);

                // Connect to SSE endpoint
                const eventSource = new EventSource(`/get_live_data?topic=${encodeURIComponent(mqttTopic)}`);

                eventSource.onmessage = function (event) {
                    const message = JSON.parse(event.data);
                    const payloadValue = JSON.parse(message.payload);
                    const newMessage = { 'topic': message.topic, 'payload': payloadValue }
        
                    setReceivedMessages(prevMessages => {
                        const filteredMessages = prevMessages.filter(message => message.payload.object_id !== newMessage.payload.object_id);
                        return [newMessage, ...filteredMessages];
                    });
                };

                 // MAYBE WAY TO HANDLE IT IN THE FUCTURE
                // handleOnMessage(eventSource, setReceivedMessages);
                // handleIncrementCount(eventSource, mqttTopic);

                localStorage.setItem("showSettings", "yes");
                localStorage.setItem("page", "alertsPage");
                setReceivedMessages([]); // to clear and data that came in before
                setSubscribeTopic(mqttTopic);
                navigate('/')
            } else {
                localStorage.setItem("showSettings", "no");
                localStorage.setItem("page", "connectionPage");
                navigate('/connection');
            };
        } catch (error) {
            console.log(error)
            setGoogleErrorMsg(error.response.data.error)
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: '100vh'
            }}
        >
            <FormLabel
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "2vh",
                    border: "1px solid grey",
                    borderRadius: "8px"
                }}
            >
                <TextField
                    required
                    sx={{
                        margin: "5vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                    id="email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                />
                <TextField
                    required
                    type="password"
                    sx={{
                        margin: "1vh 5vh 5vh 5vh",
                        width: "300px",
                    }}
                    id="password"
                    label="Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                />
            </FormLabel>
            <Typography
                sx={{
                    color: "red",
                    marginBottom: "2vh"
                }}
            >
                {errorMsg}
            </Typography>

            <Button
                variant="contained"
                sx={{
                    width: "370px",
                    backgroundColor: "#0A1B3A",
                    borderRadius: "30px",
                    '&:hover': {
                        backgroundColor: "#37465E",
                    }
                }}
                onClick={() => { logInUser() }}
            >
                Log in
            </Button>
            <Box
                sx={{
                    display: "flex",
                    margin: "2vh"
                }}            >
                <Typography>
                    or
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "370px",
                    marginBottom: "3vh"
                }}
            >
                <GoogleLogin
                    onSuccess={(credentialResponse) => {
                        const credentialResponseDecode = jwtDecode(credentialResponse.credential)
                        // console.log(credentialResponseDecode)
                        const email = credentialResponseDecode.email
                        googleLogInUser(email);
                    }}
                    onError={() => {
                        console.log('LoginFailed')
                    }}
                />
            </Box>
            <Typography
                sx={{ color: "red" }}
            >
                {googleErrorMsg}
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Typography>Don't have an account?</Typography>
                <Button
                    sx={{ fontWeight: "bold" }}
                    component={Link}
                    to={`/Register`}
                >
                    Sign up
                </Button>
            </Box>
        </Box >
    )
}

export default LoginPage;