import { useEffect, useRef, useState } from "react";
import { Popup, Circle, useMapEvents, Tooltip } from "react-leaflet";
import { SemiCircle } from "react-leaflet-semicircle";
import { useRecoilState } from "recoil";
import { selectedMarkerIdAtom, isMovingPositionByClickAtom, flyToElementIdAtom, allSensorsAtom } from "../../../recoil/atom";
import { sensorsSelector } from "../../../recoil/selector";

import useStyles from "../styles";
import { computeAngleOne, computeAngleTwo, defaultConverter } from "../../../utils";
import { useMarkerTools } from "../../../hooks/useMarkerTools";
import CircleTool from "./CircleTool";
// import { Sensor } from "../../../models";

const SensorMarker = ({ sensorId }) => {
    const classes = useStyles();
    const { getIsEditMarkerActive } = useMarkerTools();
    const [showText, setShowText] = useState(false);
    const [flyToElementId, setFlyToElement] = useRecoilState(flyToElementIdAtom);
    const [selectedMarkerId, setSelectedMarkerId] = useRecoilState(selectedMarkerIdAtom);
    const [isMovingPositionByClick, setIsMovingPositionByClick] = useRecoilState(isMovingPositionByClickAtom);
    // const [startAngle, setStartAngle] = useState(() => computeAngleOne(azimuth, azimuthOffset, angleOfInterest));
    // const [stopAngle, setStopAngle] = useState(() => computeAngleTwo(azimuth, azimuthOffset, angleOfInterest));
    const [startAngle, setStartAngle] = useState(null);
    const [stopAngle, setStopAngle] = useState(null);

    const [sensor, setSensor] = useRecoilState(sensorsSelector(sensorId));
    const [allSensors, setAllSensors] = useRecoilState(allSensorsAtom);

    // if (!sensor) {
    //     return null;
    // }

    const {
        id,
        type,
        coordinates,
        range,
        azimuth,
        azimuthOffset,
        crossingAngle,
        angleOfInterest,
        circleTool,
        isDisplay,
        labelText,
        labelColor,
        labelFontSize,
        displayAntennaLines,
    } = sensor || {};

    const center = [+coordinates[0], +coordinates[1]];

    const map = useMapEvents({
        click: (e) => {
            if (isMovingPositionByClick) {
                if (selectedMarkerId?.id && selectedMarkerId.id === id && sensor) {
                    const newSensor = {
                        ...sensor,
                        coordinates: [
                            e.latlng.lat,
                            e.latlng.lng,
                            sensor.coordinates[2],
                        ],
                    };

                    setSensor(newSensor);
                }
            }
        },
    });

    useEffect(() => {
        if (!sensor) return;

        let one = computeAngleOne(azimuth, azimuthOffset, angleOfInterest);
        let two = computeAngleTwo(azimuth, azimuthOffset, angleOfInterest);

        setStartAngle(one);
        setStopAngle(two);
    }, [azimuth, azimuthOffset, angleOfInterest]);

    useEffect(() => {
        if (!allSensors.find(s => s.id === sensor.id)) {
            // console.log(sensor.id)
            setAllSensors(prevSensors => [...prevSensors, sensor]);
        };
    }, [sensor]);

    useEffect(() => {
        if (isMovingPositionByClick) {
            map._container.style.cursor = "crosshair";
        } else {
            map._container.style.cursor = "pointer";
        }
    }, [isMovingPositionByClick]);

    const popupRef = useRef();

    const handleClosePopUp = () => {
        popupRef.current._closeButton.click();
    };

    useEffect(() => {
        if (flyToElementId === id) {
            map.flyTo([+sensor.coordinates[0], +sensor.coordinates[1]], 21);
            setFlyToElement(null);

            const blinkInterval = setInterval(() => {
                setSensor((prev) => ({ ...prev, isDisplay: !prev.isDisplay }));
            }, 500);

            setTimeout(() => {
                clearInterval(blinkInterval);
                setSensor((prev) => ({ ...prev, isDisplay: true }));
            }, 5000);
        }
    }, [flyToElementId]);


    function onClick() {
        getIsEditMarkerActive().then((isActive) => {
            if (isActive) {
                setShowText(false);
                setSelectedMarkerId({ id, type });
                setShowText(false);
            } else {
                setSelectedMarkerId(null);
                setShowText(true);
            }
        });
    }

    // console.log('sensorid:', sensorId);
    // console.log('sensor:', sensor.id);
    // console.log('allsensor:', allSensors);
    // console.log('corrdinated', coordinates);
    // console.log('center:', center);
    // console.log('dislpay:', isDisplay);
    // console.log('sensorMarker');

    return (
        <>
            {isDisplay && (
                <>
                    <SemiCircle
                        position={center}
                        radius={range}
                        startAngle={startAngle}
                        stopAngle={stopAngle}
                        interactive={false}
                    ></SemiCircle>
                    {/* {displayAntennaLines && (

                        <>
                            <SemiCircle
                                position={center}
                                radius={range}
                                startAngle={azimuth}
                                stopAngle={azimuth + 0.00001}
                                color={"red"}
                                dashArray={[5, 15]}
                            ></SemiCircle>
                            <SemiCircle
                                position={center}
                                radius={range}
                                startAngle={(azimuth + crossingAngle) % 360}
                                stopAngle={
                                    ((azimuth + crossingAngle) % 360) + 0.00001
                                }
                                color={"green"}
                                dashArray={[5, 15]}
                            ></SemiCircle>
                            <SemiCircle
                                position={center}
                                radius={range}
                                startAngle={(azimuth + azimuthOffset) % 360}
                                stopAngle={
                                    ((azimuth + azimuthOffset) % 360) + 0.00001
                                }
                                color={"purple"}
                                dashArray={[5, 15]}
                            ></SemiCircle>
                        </>
                    )} */}
                </>
            )
            }
            {
                circleTool.isDisplay && (
                    <CircleTool
                        circleTool={{
                            ...circleTool,
                            id,
                            center: [
                                +sensor.coordinates[0],
                                +sensor.coordinates[1],
                            ],
                        }}
                    />
                )
            }
            {
                isDisplay && (
                    <Circle
                        center={center}
                        radius={0.5}
                        pathOptions={{ color: "blue", fillOpacity: 1 }}
                        eventHandlers={{
                            click: onClick,
                        }}
                    >
                        {labelText && (
                            <Tooltip permanent>
                                <span
                                    style={{
                                        color: labelColor,
                                        fontSize: `${labelFontSize}px`,
                                    }}
                                >
                                    {labelText}
                                </span>
                            </Tooltip>
                        )}
                        {showText && (
                            <Popup ref={popupRef}>
                                <div className={classes.popUpInfo}>
                                    <table>
                                        <tbody>
                                            {Object.entries(sensor).map(
                                                ([key, value]) => {
                                                    if (key === "circleTool") {
                                                        return null;
                                                    }
                                                    if (key === "coordinates") {
                                                        const [x, y] =
                                                            defaultConverter.convertFromLatLng(
                                                                ...value,
                                                            );
                                                        const z =
                                                            sensor.coordinates[2];
                                                        return (
                                                            <tr key={key}>
                                                                <td>{key}</td>
                                                                <td>
                                                                    x{" "}
                                                                    {": " +
                                                                        x.toFixed(
                                                                            2,
                                                                        )}
                                                                    <br />y{" "}
                                                                    {": " +
                                                                        y.toFixed(
                                                                            2,
                                                                        )}
                                                                    <br />z
                                                                    {": " +
                                                                        z.toFixed(
                                                                            2,
                                                                        )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }

                                                    return (
                                                        <tr key={key}>
                                                            <td>{key}</td>
                                                            <td>{value}</td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                    <button onClick={handleClosePopUp}>
                                        Close
                                    </button>
                                </div>
                            </Popup>
                        )}
                    </Circle>
                )
            }
        </>
    );
};

export default SensorMarker;
