import { selectorFamily } from "recoil";
import {
    // imageOverlayFamilyAtom,
    // imageOverlayIdsAtom,
    // lineToolFamilyAtom,
    // lineToolsIdsAtom,
    sensorsFamilyAtom,
    sensorsIdsAtom,
    targetsFamilyAtom,
    targetsIdsAtom,
    shpsFamilyAtom,
    shpsIdsAtom,
    // polygonFamilyAtom,
    // polygonsIdsAtom,
} from "./atom";

export const sensorsSelector = selectorFamily({
    key: "sensorsSelector",
    get:
        (sensorId) =>
            ({ get }) => {
                return get(sensorsFamilyAtom(sensorId));
            },

    set:
        (sensorId) =>
            ({ set, reset, get }, newSensor) => {
                const sensorsIds = get(sensorsIdsAtom);
                console.log('sensorId - sensor', sensorsIds)

                //newSensor is in array of sensors
                if (newSensor?.length > 0) {
                    const ids = new Set();

                    for (const sensor of newSensor) {
                        if (ids.has(sensor.id) || sensorsIds[0].has(sensor.id)) {
                            throw new Error(`ID: ${sensor.id} already exists`);
                        }
                        ids.add(sensor.id);
                        set(sensorsFamilyAtom(sensor.id), sensor);
                    }

                    set(sensorsIdsAtom, (prev) => [new Set([...prev[0], ...ids])]);
                    return;
                } else if (newSensor?.length === 0) {
                    return;
                }

                // if 'newSensor' is null,
                // the 'set' method will delete the atom from the atomFamily.
                if (!newSensor) {
                    set(sensorsIdsAtom, (prevValue) => {
                        prevValue[0].delete(sensorId);
                        return [prevValue[0]];
                    });
                    reset(sensorsFamilyAtom(sensorId));
                } else {
                    // if only changing its id
                    if (newSensor?.prevId) {
                        set(sensorsFamilyAtom(newSensor.id), {
                            ...newSensor,
                            prevId: null,
                        });
                        //set(sensorsIdsAtom, (prevValue) => ([...prevValue.filter((id) => id !== newSensor.prevId), newSensor.id]));
                        set(sensorsIdsAtom, (prevValue) => {
                            prevValue[0].add(newSensor.id);
                            prevValue[0].delete(newSensor.prevId);
                            return [prevValue[0]];
                        });
                        reset(sensorsFamilyAtom(newSensor.prevId));
                    } else {
                        //create new sensor
                        set(sensorsFamilyAtom(newSensor.id), newSensor);

                        if (!sensorsIds[0].has(newSensor.id)) {
                            set(sensorsIdsAtom, (prev) => [
                                prev[0].add(newSensor.id),
                            ]);
                        }
                    }
                }
            },
});

export const shpsSelector = selectorFamily({
    key: "shpsSelector",
    get:
        (shpId) =>
            ({ get }) => {
                return get(shpsFamilyAtom(shpId));
            },

    set:
        (shpId) =>
            ({ set, reset, get }, newShp) => {
                const shpsIds = get(shpsIdsAtom);
                //newShp is in array of shps
                if (newShp?.length > 0) {
                    const ids = new Set();
                    for (const shp of newShp) {
                        if (ids.has(shp.id) || shpsIds[0].has(shp.id)) {
                            throw new Error(`ID: ${shp.id} already exists`);
                        }
                        ids.add(shp.id);
                        set(shpsFamilyAtom(shp.id), shp);
                    }

                    set(shpsIdsAtom, (prev) => [new Set([...prev[0], ...ids])]);
                    return;
                } else if (newShp?.length === 0) {
                    return;
                }

                // if 'newShp' is null,
                // the 'set' method will delete the atom from the atomFamily.
                if (newShp) {
                    // if only changing its id
                    if (newShp?.prevId) {
                        set(shpsFamilyAtom(newShp.id), {
                            ...newShp,
                            prevId: null,
                        });
                        //set(sensorsIdsAtom, (prevValue) => ([...prevValue.filter((id) => id !== newSensor.prevId), newSensor.id]));
                        set(sensorsIdsAtom, (prevValue) => {
                            prevValue[0].add(newShp.id);
                            prevValue[0].delete(newShp.prevId);
                            return [prevValue[0]];
                        });
                        reset(shpsFamilyAtom(newShp.prevId));
                    } else {
                        //create new sensor
                        set(shpsFamilyAtom(newShp.id), newShp);
                        if (!shpsIds[0].has(newShp.id)) {
                            set(shpsIdsAtom, (prev) => [prev[0].add(newShp.id)]);
                        }
                    }
                }
            },
});

export const targetsSelector = selectorFamily({
    key: "targetsSelector",
    get:
        (targetId) =>
            ({ get }) => {
                return get(targetsFamilyAtom(targetId));
            },

    set:
        (targetId) =>
            ({ set, reset, get }, newTarget) => {
                const targetsIds = get(targetsIdsAtom);

                //newTarget is in array of sensors
                if (newTarget?.length > 0) {
                    console.log("hello");
                    const ids = new Set();

                    for (const target of newTarget) {
                        if (ids.has(target.id) || targetsIds[0].has(target.id)) {
                            throw new Error(`ID: ${target.id} already exists`);
                        }
                        ids.add(target.id);
                        set(targetsFamilyAtom(target.id), target);
                    }
                    set(targetsIds, (prev) => [new Set([...prev[0], ...ids])]);
                    return;
                } else if (newTarget?.length === 0) {
                    return;
                }

                // if 'newTarget' is null,
                // the 'set' method will delete the atom from the atomFamily.
                if (!newTarget) {
                    set(targetsIdsAtom, (prevValue) => {
                        prevValue[0].delete(targetId);
                        return [prevValue[0]];
                    });
                    reset(targetsFamilyAtom(targetId));
                } else {
                    // if only changing its id
                    if (newTarget?.prevId) {
                        set(targetsFamilyAtom(newTarget.id), {
                            ...newTarget,
                            prevId: null,
                        });
                        set(targetsIdsAtom, (prevValue) => {
                            prevValue[0].add(newTarget.id);
                            prevValue[0].delete(newTarget.prevId);
                            return [prevValue[0]];
                        });
                        reset(targetsFamilyAtom(newTarget.prevId));
                    } else {
                        set(targetsFamilyAtom(newTarget.id), newTarget);

                        if (!targetsIds[0].has(newTarget.id)) {
                            set(targetsIdsAtom, (prev) => [
                                prev[0].add(newTarget.id),
                            ]);
                        }
                    }
                }
            },
});