import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, FormControl, InputLabel, MenuItem, Select, Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getClientPermissions, getReverseIndexFromDB } from "../api/connection";

// FOR CONNECTION BUTTON and MAP DISPLAY
import { getSelectedDataPosition, createSensors, handleOnMessage } from "../functions/connectionFunctions"
import { useSetRecoilState, useRecoilState } from "recoil";
import { mapPositionAtom, subscribeTopicAtom, receivedMessagesAtom, eventSourceAtom } from "../recoil/atom";
import { sensorsSelector } from "../recoil/selector";

const ConnectionPage = () => {
    const [permissionsList, setPermissionsList] = useState([]);
    const [reverseIndex, setReverseIndex] = useState({});
    const [projectList, setProjectList] = useState([]);
    const [siteList, setSiteList] = useState([]);
    const [clusterList, setClusterList] = useState([]);
    const [client, setClient] = useState("");
    const [project, setProject] = useState("");
    const [site, setSite] = useState("")
    const [cluster, setCluster] = useState("");

    const setMapPosition = useSetRecoilState(mapPositionAtom);
    const setSensor = useSetRecoilState(sensorsSelector());

    const [subscribeTopic, setSubscribeTopic] = useRecoilState(subscribeTopicAtom);
    const [receivedMessages, setReceivedMessages] = useRecoilState(receivedMessagesAtom);
    const [eventSource, setEventSource] = useRecoilState(eventSourceAtom);

    const navigate = useNavigate();

    useEffect(() => {
        getPermissions();
    }, []);

    useEffect(() => {
        if (permissionsList.length > 0) {
            getReverseData();
        }
    }, [permissionsList]);


    useEffect(() => {
        setSite('');
        setSiteList([]);
        setCluster('');
        setClusterList([])
        setProject('');
        if (Object.keys(reverseIndex).length > 0 && client) {
            console.log('reverseIndex', reverseIndex)
            setProjectList(Object.keys(reverseIndex[client]))
        }
    }, [client]);

    useEffect(() => {
        setCluster('');
        setClusterList([]);
        setSite('')
        if (Object.keys(reverseIndex).length > 0 && project) {
            setSiteList(Object.keys(reverseIndex[client][project]))
        }
    }, [project]);

    useEffect(() => {
        setCluster('')
        if (Object.keys(reverseIndex).length > 0 && site) {
            setClusterList(reverseIndex[client][project][site])
        }
    }, [site]);

    // FECTH PERMISSION FOR CLIENT
    const getPermissions = async () => {
        try {
            const user_data = JSON.parse(localStorage.getItem('user'))
            const clientId = user_data['client_id'];
            const response = await getClientPermissions(clientId);

            const permissionsArray = response.data;
            permissionsArray.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
            const filteredPermissions = permissionsArray.filter(permission => permission !== "N/A");

            setPermissionsList(filteredPermissions)


        } catch (err) {
            console.log(err);
        }
    };

    // FECTH REVERSE INDEX PERMISSIONS
    const getReverseData = async () => {
        try {
            const permissionsListString = permissionsList.join(',');
            console.log(permissionsListString);
            const response = await getReverseIndexFromDB(permissionsListString);
            console.log(response.data);
            setReverseIndex(response.data);
        } catch (err) {
            console.log(err);
        };
    };

    // REFRESH FORM
    const handleRefresh = () => {
        setClient('');
        setProject('');
        setProjectList([]);
        setSite('');
        setSiteList([]);
        setCluster('');
        setClusterList([]);
        setSubscribeTopic('');
    };

    // CONNECT
    const handleConnectButton = async () => {
        setReceivedMessages([]); // to clear and data that came in before
        let topicPosition = ''
        if (client && !project) {   // only client data
            topicPosition = `alert/${client}/+/+/+`
        } else if (project && !site) { // client and project data
            topicPosition = `alert/${client}/${project}/+/+`
        } else if (site && !cluster) { // client, project and site data
            topicPosition = `alert/${client}/${project}/${site}/+`
        } else {    // all the data
            topicPosition = `alert/${client}/${project}/${site}/${cluster}`
        }

        const mqttTopic = `${topicPosition}/7`;

        const cluster_sensors = await getSelectedDataPosition(topicPosition, setMapPosition);
        createSensors(cluster_sensors, setSensor);

        // Connect to SSE endpoint
        const newEventSource = new EventSource(`/get_live_data?topic=${encodeURIComponent(mqttTopic)}`);
        setEventSource(newEventSource)
        console.log('eventSource:', eventSource)

        handleOnMessage(newEventSource, setReceivedMessages);
        // handleIncrementCount(newEventSource, mqttTopic);


        localStorage.setItem("showSettings", "yes");
        localStorage.setItem("page", "alertsPage");
        setReceivedMessages([]); // to clear and data that came in before
        setSubscribeTopic(mqttTopic);
        navigate('/');
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: '90vh'
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "4vh",
                    border: "1px solid grey",
                    borderRadius: "8px"
                }}
            >
                <FormControl
                    fullWidth
                    sx={{
                        margin: "5vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                >
                    <InputLabel id="labelClient">Client</InputLabel>
                    <Select
                        labelId="labelClient"
                        value={client}
                        label="Client"
                        onChange={(e) => setClient(e.target.value)}
                    >
                        {Object.keys(reverseIndex).map((clientPulled, index) => (
                            <MenuItem key={index} value={clientPulled}>
                                {clientPulled}
                            </MenuItem>
                        ))}

                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                >
                    <InputLabel id="labelProject">Project</InputLabel>
                    <Select
                        disabled={!client}
                        labelId="labelProject"
                        value={project}
                        label="Project"
                        onChange={(e) => setProject(e.target.value)}
                    >
                        {projectList.map((projectPulled, index) => (
                            <MenuItem key={index} value={projectPulled}>
                                {projectPulled}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        margin: "0vh 5vh 1vh 5vh",
                        width: "300px",
                    }}
                >
                    <InputLabel id="labelSite">Site</InputLabel>
                    <Select
                        disabled={!project}
                        labelId="labelSite"
                        value={site}
                        label="Site"
                        onChange={(e) => setSite(e.target.value)}
                    >
                        {siteList.map((sitePulled, index) => (
                            <MenuItem key={index} value={sitePulled}>
                                {sitePulled}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{
                        margin: client ? "0vh 5vh 2vh 5vh" : "0vh 5vh 5vh 5vh",
                        width: "300px",
                    }}
                >
                    <InputLabel id="labelCluster">Cluster</InputLabel>
                    <Select
                        disabled={!site}
                        labelId="labelCluster"
                        value={cluster}
                        label="Cluster"
                        onChange={(e) => setCluster(e.target.value)}
                    >

                        {clusterList.map((clusterPulled, index) => (
                            <MenuItem key={index} value={clusterPulled}>
                                {clusterPulled}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {client && (
                    <RefreshIcon fontSize="small" sx={{
                        margin: "0vh 5vh 4vh 5vh"
                    }}
                        onClick={handleRefresh}
                    />
                )}
            </Box>
            <Button
                disabled={!client}
                variant="contained"
                sx={{
                    width: "370px",
                    borderRadius: "30px",
                }}
                color="success"
                onClick={handleConnectButton}
            >
                Connect
            </Button>
        </Box >
    )
}

export default ConnectionPage;