import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    popUpTarget: {
        width: 400,
    },

    popUpInfo: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },

    tableTarget: {
        fontSize: 12,
        display: "flex",
        justifyContent: "space-between",
    },

    tcol: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    tcell: {
        marginTop: 1,
    },

    clusterTargets: {
        textAlign: "center",
        borderRadius: 40,
        backgroundColor: "red",
    },
}));
