import proj4 from "proj4";
import {areaDescription, allProj4Defs} from "./Proj4EPSG";

class Converter {
    static allProj4Defs = allProj4Defs;
    static areaDescription = areaDescription;

    constructor(inProj, outProj = 4326, offsets={"2039":[-6.45238, -7.22762]}) {
        this.inProj = inProj;
        this.outProj = outProj;
        this.offsets = offsets;
        this.converter = proj4(
            allProj4Defs[`EPSG:${inProj}`],
            allProj4Defs[`EPSG:${outProj}`],
        );
    }

    setConverter(inProj, outProj = 4326) {
        this.inProj = inProj;
        this.outProj = outProj;
        this.converter = proj4(
            allProj4Defs[`EPSG:${inProj}`],
            allProj4Defs[`EPSG:${outProj}`],
        );
    }

    setOffsets(proj, offsets) {
        this.offsets[proj] = offsets;
    }

    convertToLatLng(x, y) {
        const xValue = this.offsets?.[this.inProj]? x+this.offsets?.[this.inProj][0] : x;
        const yValue = this.offsets?.[this.inProj]? y+this.offsets?.[this.inProj][1] : y;
        const [lng, lat] = this.converter.forward([xValue, yValue]);
        return [lat, lng];
    }

    convertFromLatLng(lat, lng) {
        const [x, y] = this.converter.inverse([+lng, +lat]);
        const xValue = this.offsets?.[this.inProj]? x-this.offsets?.[this.inProj][0] : x;
        const yValue = this.offsets?.[this.inProj]? y-this.offsets?.[this.inProj][1] : y;
        return [xValue, yValue];
    }
}

const defaultConverter = new Converter(2039, 4326);

export { defaultConverter, Converter };
