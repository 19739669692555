import L from "leaflet";

export class Sensor {
    static type = "Sensor";

    constructor({
        id = null,
        coordinates,
        range,
        angleOfInterest,
        azimuth,
        azimuthOffset,
        crossingAngle,
        elevation,
        circleTool,
        labelFontSize = "14",
        labelColor = "#000000",
        labelText = "",
        mounted = false,
        wall = "",
        mountedAzimuth = "",
        angleLimit = "",
        polylineId = "",
    }) {
        this.id = id || new Date().getTime();
        this.type = "Sensor";
        this.coordinates = coordinates || [null, null];
        if (this.coordinates?.length === 2) this.coordinates.push(0);
        this.range = +range || 100;
        this.angleOfInterest = +angleOfInterest || 48;
        this.azimuth = +azimuth || 0;
        this.azimuthOffset = +azimuthOffset || 0;
        this.crossingAngle = +crossingAngle || 0;
        this.elevation = +elevation || 0;
        this.labelFontSize = labelFontSize;
        this.labelColor = labelColor;
        this.labelText = labelText;
        this.mounted = mounted;
        this.wall = wall;
        this.mountedAzimuth = +azimuth || 0;
        this.angleLimit = +angleLimit || 21;
        if (circleTool && typeof circleTool === "object")
            this.circleTool = circleTool;
        else
            this.circleTool = {
                isDisplay: false,
                radius: range / 2 || 50,
            };
        this.isDisplay = true;
        this.displayAntennaLines = true;
        this.polylineId = polylineId;
    };
};

export class LineTool {
    static type = "LineTool";
    constructor({
        id = null,
        idAcc = "",
        positions,
        color = "#dd0000",
        angle,
        distance,
        lineWidth = 3,
        dashLine = 10,
        dashSpace = 0,
        labelText = "",
        labelColor = "#000000",
        labelFontSize = "14",
        sensorMounted,
        sensorMountedArray = [],
    }) {
        this.id = id || idAcc + new Date().getTime().toString();
        this.type = "LineTool";
        this.color = color;
        positions[0] = L.latLng(positions[0]);
        positions[1] = L.latLng(positions[1]);
        this.positions = positions;
        this.angle = angle;
        this.distance = distance;
        this.lineWidth = lineWidth;
        this.dashLine = dashLine;
        this.dashSpace = dashSpace;
        this.isDisplay = true;
        this.labelText = labelText;
        this.labelColor = labelColor;
        this.labelFontSize = labelFontSize;
        this.sensorMounted = sensorMounted;
        this.sensorMountedArray = sensorMountedArray;
    }
}

export class Polygon {
    static type = "Polygon";
    constructor({
        id = null,
        idAcc = "",
        positions,
        lineColor = "#dd0000",
        lineWidth = 3,
        labelText = "",
        labelColor = "#000000",
        labelFontSize = "14",
        fillColor = "#dd0000",
        fillOpacity = 0.1,
        opacity = 1,
    }) {
        this.id = id || idAcc + new Date().getTime().toString();
        this.type = "Polygon";
        this.lineColor = lineColor;
        this.positions = positions;
        this.lineWidth = lineWidth;
        this.isDisplay = true;
        this.labelText = labelText;
        this.labelColor = labelColor;
        this.labelFontSize = labelFontSize;
        this.fillColor = fillColor;
        this.fillOpacity = fillOpacity;
        this.opacity = opacity;
    }
}

export class ShpFile {
    static type = "ShpFile";
    constructor({
        id = null,
        isNew = true,
        data,
        isDisplay = true,
        isModified = false,
    }) {
        this.id = id;
        this.type = "ShpFile";
        this.data = data;
        this.isDisplay = isDisplay;
        this.isNew = isNew;
        this.isModified = isModified;
    }
}

export class Target {
    static type = "Target";

    constructor({
        id = null,
        coordinates,
        color = "#dd0000",
        idAcc = "",
        diameter = 1,
        labelText = "",
        labelColor = "#000000",
        labelFontSize = "14",
    }) {
        this.id = id || new Date().getTime().toString() + idAcc;
        this.type = "Target";
        this.coordinates = coordinates;
        this.color = color;
        if (this.coordinates.length === 2) this.coordinates.push(0);
        this.isDisplay = true;
        this.diameter = diameter;
        this.labelText = labelText;
        this.labelColor = labelColor;
        this.labelFontSize = labelFontSize;
    }
}

