import "./styles.css";
import { MapContainer, TileLayer, ScaleControl, LayersControl } from "react-leaflet";
import Markers from "./../markers/Markers";
import { useEffect, useState, useRef, useContext } from "react";
import { useRecoilValue } from "recoil";
import { mapPositionAtom } from "../../recoil/atom.js";
import ShpJSON from "./ShpJSON.js";
import MapContext from "./../context/MapContext";
import "leaflet/dist/leaflet.css"

const MAX_ZOOM = 25;
const MIN_ZOOM = 3;

const maps = [
    {
        url: "http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
        name: "Google Road",
        attribution:
            'Map data &copy; <a target="_blank" href="https://maps.google.com/">GoogleMaps</a> | Icons by <a target="_blank" href="https://icons8.com">Icon 8</a>',
    },
    {
        url: "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
        name: "Google Satellite",
        attribution:
            'Map data &copy; <a target="_blank" href="https://maps.google.com/">GoogleMaps</a> | Icons by <a target="_blank" href="https://icons8.com">Icon 8</a>',
    },
];

const Map2 = ({ setMap }) => {
    // console.log("rendering Map");
    const [zoomLevel, setZoomLevel] = useState(10);
    const position = useRecoilValue(mapPositionAtom);
    const mapRef = useRef(null);

    useEffect(() => {
        const map = mapRef.current?.leafletElement;
        setMap(map);
    }, [setMap]);

    // console.log(position)

    useEffect(() => {
        setZoomLevel(10);
    }, [position]);

    // console.log('running map')

    return (
        <>
            <MapContainer
                center={position}
                zoom={zoomLevel}
                scrollWheelZoom={true}
                zoomControl={true}
                attributionControl={true}
                preferCanvas={true}
                key={"map"}
                ref={mapRef}
            >
                <LayersControl position="topright">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        minZoom={MIN_ZOOM}
                        maxZoom={MAX_ZOOM}
                        maxNativeZoom={19}
                    />

                    <LayersControl.BaseLayer checked name="Leaflet">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='Map data &copy; <a className="copyrightLink" target="_blank" href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a target="_blank" href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a> | Icons by <a target="_blank" href="https://icons8.com">Icon 8</a>'
                            minZoom={MIN_ZOOM}
                            maxZoom={MAX_ZOOM}
                            maxNativeZoom={19}
                        />
                    </LayersControl.BaseLayer>
                    {maps.map((ele) => {
                        return (
                            <LayersControl.BaseLayer
                                name={`${ele.name}`}
                                key={ele.name}
                            >
                                <TileLayer
                                    attribution={ele.attribution}
                                    url={ele.url}
                                    minZoom={MIN_ZOOM}
                                    maxZoom={MAX_ZOOM}
                                    maxNativeZoom={19}
                                    subdomains={["mt0", "mt1", "mt2", "mt3"]}
                                />
                            </LayersControl.BaseLayer>
                        );
                    })}
                </LayersControl>
                <ShpJSON />
                <Markers />
                <ScaleControl position="bottomright" />
            </MapContainer>
        </>
    );
};

const Map = (props) => {
    const { setMap } = useContext(MapContext);

    return <Map2 {...props} setMap={setMap} />;
};

export default Map;
