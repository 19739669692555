import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { mapPositionAtom, typeOfNewMarkerAtom, showSensorConfigAtom } from "../../recoil/atom";
import ListSensorMarker from "./listSensor/ListSensorMarker";
import useEffectAfterMount from "../../hooks/useEffectAfterMount";
import LiveDataMarker from "./listLiveData/LiveDataMarker";


const Markers = () => {
    const map = useMap();
    const position = useRecoilValue(mapPositionAtom);
    const [isLoadingMarkers, setIsLoadingMarkers] = useState(true);
    const [typeOfNewMarker, setTypeOfNewMarker] = useRecoilState(typeOfNewMarkerAtom);
    const [showSensorConfig, setShowSensorConfig] = useRecoilState(showSensorConfigAtom);

    useEffect(() => {
        // console.log("fetching markers");

        (async () => { })();
        setIsLoadingMarkers(false);
    }, []);

    useEffectAfterMount(() => {
        map.flyTo(position, 16);
    }, [position]);

    useEffect(() => {
        if (typeOfNewMarker) {
            map._container.style.cursor = "crosshair";
        } else {
            map._container.style.cursor = "pointer";
        }
    }, [typeOfNewMarker]);

    return (
        <div>
            {!isLoadingMarkers && (
                <div>
                    {showSensorConfig && (
                        <ListSensorMarker />
                    )}
                    <LiveDataMarker map={map} />

                </div>
            )}
        </div>
    );
};

export default Markers;
