
import { jwtDecode } from 'jwt-decode';

export const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

export const isTokenExpired = (token) => {
    if (!token || token === null || typeof token !== 'string') {
        console.error("Invalid or null token:", token);
        return true; // Consider token invalid if it's not a string
    }

    try {
        const decoded = jwtDecode(token);
        if (decoded.exp < Date.now() / 1000) {
            console.error("Exprired token:", token);
            return true; // Token is expired
        };
        return false; // Token is valid
    } catch (error) {
        console.error("Error decoding token:", error);
        return true; // If there's an error, consider the token invalid
    };
};